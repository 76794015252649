import React, { useEffect } from 'react'
import { UserData } from './common/types'
import i18next from 'i18next'

export const PageBanner = ({ userData }: { userData: UserData | undefined }) => {
  useEffect(() => {
    const selectedlanguage = document.querySelector(`input[name="language"][value="${i18next.language}"]`)
    selectedlanguage?.setAttribute('checked', 'true')
  }, [])

  return (
    <div className="header-banner">
      <div className="organization">
        <div>Ylioppilastutkintolautakunta</div>
        <div>Studentexamensnämnden</div>
      </div>
      {userData?.user?.mock && (
        <a id="mock" href="/mock">
          change mock user
        </a>
      )}
      <div className="user-actions">
        <div className="language-selection">
          <label>
            <input type="radio" name="language" value="fi" onClick={e => changeLanguage(e.currentTarget.value)} />
            <span className="radio-label">FI</span>
          </label>
          <label>
            <input type="radio" name="language" value="sv" onClick={e => changeLanguage(e.currentTarget.value)} />
            <span className="radio-label">SV</span>
          </label>
        </div>

        {userData?.roles && (
          <div className="user-area">
            <span className="logged-in-user user-name">
              {userData?.user?.firstName} {userData?.user?.lastName}
            </span>
            <a id="vetuma-logout" onClick={() => window.location.assign('/kurko-api/user/logout')} />
          </div>
        )}
      </div>
    </div>
  )
  function changeLanguage(newLanguage: string) {
    i18next.changeLanguage(newLanguage).catch(err => console.error(err))
    document.querySelector('body')?.setAttribute('language', newLanguage)
  }
}
