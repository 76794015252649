import { ExamType } from '../grading/new/types'

export function examTypeAbbr(examType: string, delimiter: string = '-'): string {
  return examType === 'visually-impaired' ? `${delimiter}vi` : examType === 'hearing-impaired' ? `${delimiter}hi` : ''
}

export function mkresolveExamContentURI(examCode: string, eventDate: string, language: string, examType: ExamType) {
  const eventDateStr = eventDate ? `${eventDate}_` : ''
  const dirname = `${eventDateStr}${examCode}_${language}${examTypeAbbr(examType, '_')}`
  return (path: string) => `/exam-material/${dirname}/${path}`
}
