import { orderBy } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { UserDataContext } from '../app'
import { LoadingSpinner } from '../common/loading-spinner'
import { T } from '../common/translate'
import { SchoolPersonnelRole, TabProps } from '../common/types'
import { useAxiosGet } from '../hooks'
import { AnswersUploadPanel } from './answers-upload-panel'
import { formatPregradingStatus } from './format-held-exams'
import { ArpaExamPregradingStatus, ExamPregradingStatus } from './types'
import { HeldExam } from './held-exam'

export const Pregrading = ({ scopeId, roleType }: TabProps) => {
  const [heldExams, setHeldExams] = useState<ExamPregradingStatus[]>([])
  const [examsWithGrader, setExamsWithGrader] = useState<string[]>([])
  const [get, loading] = useAxiosGet()
  const { roles } = useContext(UserDataContext)

  const loadHeldExams = async (schoolId: string) => {
    const data = await get<ArpaExamPregradingStatus[]>(`/exam-api/exams/${schoolId}/held-exams`)

    if (data) {
      setHeldExams(formatPregradingStatus(data))
    }
  }

  useEffect(() => {
    void (async () => {
      if (roleType !== 'PRINCIPAL') return setExamsWithGrader([])
      const data = await get<string[]>(`/exam-api/grading/${scopeId}/exams-with-grader`)
      if (data) {
        setExamsWithGrader(data)
      }
    })()
  }, [roleType, scopeId])

  useEffect(() => void loadHeldExams(scopeId), [scopeId])

  const role = roles!.find(
    role => role.roleType === 'GRADING_TEACHER' && role.schoolId === scopeId
  ) as SchoolPersonnelRole

  const allowedExams = role?.allowedExams || []

  const heldExamsWithAllowedInformation = heldExams.map(exam => ({
    ...exam,
    isAllowedExam: allowedExams.includes(exam.examCode.replace(/_X|_E/, '')) || roleType === 'PRINCIPAL'
  }))

  const orderedHeldExams = orderBy(
    heldExamsWithAllowedInformation,
    ['isAllowedExam', 'eventDate', 'title', 'schoolAnonCode'],
    ['desc', 'asc', 'asc', 'asc']
  )

  return (
    <div id="exam-grading-tab" className="tab">
      <LoadingSpinner loading={loading} />
      <AnswersUploadPanel schoolId={scopeId} isPrincipal={roleType === 'PRINCIPAL'} loadHeldExams={loadHeldExams} />
      <h3>
        <T>sa.held_exams</T>
      </h3>
      <p>
        <T>sa.info_text</T>
      </p>
      <table id="held-exams" className="is_pregrading held-exams-table basic-table">
        <thead>
          <tr>
            <th>
              <T>sa.yo.exam_date</T>
            </th>
            <th>
              <T>sa.held_exam_name</T>
            </th>
            <th>
              <T>sa.yo.school_anon_code</T>
            </th>
            <th>
              <T>sa.students</T>
            </th>
            <th>
              <T>sa.held_answers</T>
            </th>
            <th>
              <div className="pregrading_graded">
                <T>sa.pre_grading_answers_graded</T>
              </div>
              <div className="pregrading_finished">
                <T>sa.pre_grading_answers_finished</T>
              </div>
            </th>
            <th>
              <T>sa.recommended_deadline</T>
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {orderedHeldExams.map(exam => (
            <HeldExam
              key={exam.uuid}
              {...exam}
              schoolId={scopeId}
              activeRole={roleType}
              examHasGrader={examsWithGrader.includes(exam.examCode.replace(/_X|_E/, ''))}
              pregradingScores={exam.pregradingScores}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}
