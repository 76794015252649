import classNames from 'classnames'
import { differenceInCalendarDays } from 'date-fns'
import React from 'react'
import { localDateStringWithWeekdayAndYear } from '../common/client-utils-commonjs'
import { T, useLanguage } from '../common/translate'
import { RoleType } from '../common/types'
import { GradingProgress } from './grading-progress'
import { ExamPregradingStatus } from './types'

export const HeldExam = ({
  canBePregraded,
  eventDate,
  title,
  schoolAnonCode,
  answerPapers,
  answers,
  autogradedScores,
  teacherGradingCompleted,
  teacherGradingProgress,
  teacherGradingFinishedProgress,
  activeRole,
  examHasGrader,
  pregradingScores,
  pregradingFinishedCount,
  pregradingDeadlines,
  isAllowedExam
}: ExamPregradingStatus & {
  schoolId: string
  activeRole: RoleType
  examHasGrader: boolean
  isAllowedExam: boolean
}) => {
  const isPrincipal = activeRole === 'PRINCIPAL'
  const lang = useLanguage()
  const pregradableAnswerCount = answers - autogradedScores

  const answersLeftUntilTarget = (target: number) =>
    Math.ceil(target * pregradableAnswerCount - pregradingFinishedCount)

  const deadlineIsTodayAndNotDone = Object.values(pregradingDeadlines).some(deadline => {
    const deadlineisToday = differenceInCalendarDays(deadline.date, new Date()) === 0
    return deadlineisToday && answersLeftUntilTarget(deadline.target) > 0
  })

  const isLateFromAnyDeadline = Object.values(pregradingDeadlines).some(deadline => {
    const deadlineHasPassed = differenceInCalendarDays(deadline.date, new Date()) < 0
    return deadlineHasPassed && answersLeftUntilTarget(deadline.target) > 0
  })

  const nextDeadline =
    answersLeftUntilTarget(pregradingDeadlines.intDeadline.target) > 0
      ? pregradingDeadlines.intDeadline
      : pregradingDeadlines.finalDeadline

  return (
    <tr
      className={classNames(canBePregraded ? 'is_grading-active' : 'is_grading-finished', {
        disabled: !isAllowedExam
      })}>
      <td>{localDateStringWithWeekdayAndYear({ date: eventDate, showWeekday: true, lang })}</td>
      <td>{title}</td>
      <td>{schoolAnonCode}</td>
      <td>{answerPapers}</td>
      <td data-testid="pregradable-answer-count">{pregradableAnswerCount}</td>
      <td className="progress">
        {examHasGrader || teacherGradingProgress > 0 || !isPrincipal ? (
          pregradableAnswerCount === 0 ? (
            <T>sa.nothing_to_grade</T>
          ) : (
            <>
              <GradingProgress
                gradingProgress={teacherGradingProgress}
                label={pregradingScores.toString()}
                gradingCompleted={teacherGradingCompleted}
                testId="pregrading-progress"
                small
              />
              <GradingProgress
                gradingProgress={teacherGradingFinishedProgress}
                label={pregradingFinishedCount.toString()}
                gradingCompleted={teacherGradingFinishedProgress === 100}
                testId="pregrading-finished-progress"
                dateTargets={pregradingDeadlines}
              />
            </>
          )
        ) : (
          <span className="exam-with-no-grader">
            <T>sa.pregrading.exam_with_no_grader</T>
          </span>
        )}
      </td>
      <td className={classNames('work-left', { late: isLateFromAnyDeadline })} data-testid="next-deadline">
        {teacherGradingFinishedProgress < 100 ? (
          <>
            <span>
              <div className="target-date">
                <div className="date">
                  {localDateStringWithWeekdayAndYear({ date: nextDeadline.date, showWeekday: true, lang })}
                </div>
                {isLateFromAnyDeadline ? (
                  <div className="late">
                    <T>sa.late</T>
                  </div>
                ) : (
                  deadlineIsTodayAndNotDone && (
                    <div className="late">
                      <T>sa.today</T>
                    </div>
                  )
                )}
              </div>
            </span>

            <div>
              <T>sa.still_to_be_finished</T> <b>{answersLeftUntilTarget(nextDeadline.target)}</b> <T>sa.gradings</T>
            </div>
          </>
        ) : (
          <span className="green">
            ✔ <T>sa.pregrading_ready</T>
          </span>
        )}
      </td>
      {isAllowedExam ? (
        <>
          <td className="grading-links">
            <a className="button for_grading-active" href={`/school/grading/${schoolAnonCode}`}>
              <T>sa.held_exam_grade</T>
            </a>
            <a className="for_grading-finished" href={`/school/grading/${schoolAnonCode}`}>
              <T>sa.held_exam_submitted</T>
            </a>
          </td>
        </>
      ) : (
        <td colSpan={2}>
          <div className="not-allowed-exam">
            <i>
              <T>sa.pregrading.no_access</T>
            </i>
          </div>
        </td>
      )}
    </tr>
  )
}
