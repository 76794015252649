import axios, { AxiosError } from 'axios'
import $ from 'jquery'
import React, { useEffect, useRef, useState } from 'react'
import * as spinnerUtils from '../common/spinner-utils'
import { T, translate } from '../common/translate'
import { useAxiosGet } from '../hooks'

export const AnswersUploadPanel = ({
  schoolId,
  isPrincipal,
  loadHeldExams
}: {
  schoolId: string
  isPrincipal: boolean
  loadHeldExams: (schoolId: string) => Promise<void>
}) => {
  const uploadRef = useRef<HTMLInputElement>(null)
  const [uploadError, setUploadError] = useState<string | null>(null)
  const [uploadResult, setUploadResult] = useState<SchoolWithAnswer[]>([])
  const [uploading, setUploading] = useState<boolean>(false)
  const [get] = useAxiosGet()

  const [unmappedStudents, setUnmappedStudents] = useState<UnMappedStudent[]>([])

  useEffect(() => (uploading ? startSpinner() : stopSpinner()), [uploading])
  useEffect(() => void loadUnmappedStudents(schoolId), [isPrincipal, schoolId])

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    uploadRef.current?.click()
  }

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (!file || file.name.trim() === '') return
    setUploading(true)
    setUploadError(null)
    setUploadResult([])

    const formData = new FormData()
    formData.append('examUpload', file)
    axios
      .post<SchoolWithAnswer[]>(`/exam-api/answer-packages/school/${schoolId}`, formData, {
        headers: {
          enctype: 'multipart/form-data'
        }
      })
      .then(async response => {
        setUploadResult(response.data)
        await loadHeldExams(schoolId)
        await loadUnmappedStudents(schoolId)
        return
      })
      .catch((error: AxiosError) => {
        setUploadError(getUploadErrorMessageKey(error))
      })
      .finally(() => setUploading(false))
  }

  async function loadUnmappedStudents(schoolId: string) {
    if (!isPrincipal) return setUnmappedStudents([])
    const data = await get<UnMappedStudent[]>(`/exam-api/grading/${schoolId}/unmapped-students`)
    if (data) {
      setUnmappedStudents(data)
    }
  }

  const startSpinner = () => {
    const $uploadForm = $('#answers-upload-panel')
    spinnerUtils.ui.startSpinner($uploadForm.find('.upload-spinner'), spinnerUtils.ui.spinnerYtlVerySmallOpts)
  }

  const stopSpinner = () => {
    const $uploadForm = $('#answers-upload-panel')
    $uploadForm.find('.upload-input').val('')
    spinnerUtils.ui.stopSpinner($uploadForm.find('.upload-spinner'))
  }

  return (
    <div id="answers-upload-main-panel">
      <div id="answers-upload">
        <form id="answers-upload-panel" name="answers-upload" method="POST" action="">
          <p className="import-answers-instructions">
            <T>sa.import_answers_instructions</T>
          </p>
          <div className="answers-upload-button-wrapper">
            <label htmlFor="examUpload">
              <button className="upload-button" disabled={uploading} onClick={e => handleClick(e)} tabIndex={0}>
                <T>sa.import_answers</T>
              </button>
            </label>
            {uploading && (
              <div className="upload-takes-long-container">
                <span className="upload-spinner"></span>
                <span className="notification-text">
                  <T>sa.answer_upload_takes_time</T>
                </span>
              </div>
            )}
          </div>
          <input
            id="examUpload"
            className="upload-input"
            ref={uploadRef}
            type="file"
            name="examUpload"
            accept=".meb"
            onChange={e => handleFile(e)}
          />
          {uploadError && (
            <div className="upload-error error-notice">
              <T>{`${uploadError}`}</T>
            </div>
          )}
          {uploadResult.length > 0 && <UploadNotification schools={uploadResult} />}
        </form>
      </div>
      {unmappedStudents.length > 0 && <UnMappedStudents students={unmappedStudents} />}
    </div>
  )
}

type SchoolWithAnswer = {
  gradingSchoolId: string
  gradingSchoolName: string
  answerCountsByExam: { examUuid: string; examTitle: string; answerCount: number }[]
}

type UnMappedStudent = {
  lastName: string
  firstNames: string
  ssn: string
  examTitles: string[]
}

function getUploadErrorMessageKey(error: AxiosError) {
  const data = error?.response?.data as { errorCode: string }
  switch (error?.response?.status) {
    case 422:
      return 'sa.errors.no_answer_papers'
    case 428:
      return 'sa.errors.grading_started'
    case 415:
      return 'sa.errors.exam_meb'
    case 409:
      return `sa.errors.${data.errorCode || 'incorrect_exam'}`
    case 400:
      return 'sa.errors.invalid_file'
    default:
      return 'sa.errors.answer_upload_failed'
  }
}

const UploadNotification = ({ schools }: { schools: SchoolWithAnswer[] }) => {
  const count = schools
    .filter(school => !school.gradingSchoolId)
    .flatMap(school => school.answerCountsByExam)
    .reduce((sum, e) => sum + e.answerCount, 0)

  const filteredSchools = schools.filter(school => !!school.gradingSchoolId)

  const $answersUploadedNotification = $('.answers-uploaded-notification')
  $answersUploadedNotification.css({ opacity: '0' }).slideDown(100, () => {
    $answersUploadedNotification.animate({ opacity: '100' }, 1000)
  })

  const handleCloseClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    $answersUploadedNotification.css({ opacity: '100' }).slideUp({
      duration: 100,
      complete: function () {
        $answersUploadedNotification.animate({ opacity: '0' }, 1000)
      }
    })
  }

  return (
    <div className="answers-uploaded-notification">
      <div className="upload-notification-title">
        <T>sa.answers_uploaded.title</T>
      </div>
      {count > 0 && (
        <div className="without-school">
          <T params={{ count: count }}>sa.answers_uploaded.no_registration</T>
        </div>
      )}
      <table>
        {filteredSchools.map(({ gradingSchoolId, gradingSchoolName, answerCountsByExam }) => (
          <tbody key={gradingSchoolId}>
            <tr className="school-title">
              <td colSpan={2}>
                {gradingSchoolName ? (
                  gradingSchoolName
                ) : (
                  <span>
                    <T>sa.answers_uploaded.unknown_school</T>
                  </span>
                )}
                :
              </td>
            </tr>
            {answerCountsByExam.map(({ examUuid, examTitle, answerCount }, index) => (
              <tr key={examUuid}>
                <td className="exam-title">{examTitle}</td>
                <td className="answer-count">{answerCount}</td>
              </tr>
            ))}
          </tbody>
        ))}
      </table>
      <button id="answers-uploaded-close" onClick={handleCloseClick}>
        <T>sa.answers_uploaded.close</T>
      </button>
      <div className="clear-both" />
    </div>
  )
}

const UnMappedStudents = ({ students }: { students: UnMappedStudent[] }) => (
  <div className="unmapped-students js-unmapped-students">
    <h3>
      <T>sa.unmapped_students.title</T>
    </h3>
    <table className="basic-table">
      <thead>
        <tr>
          <th>
            <T>sa.unmapped_students.name</T>
          </th>
          <th>
            <T>sa.unmapped_students.ssn</T>
          </th>
          <th>
            <T>sa.unmapped_students.exam</T>
          </th>
        </tr>
      </thead>
      <tbody>
        {students.map(({ lastName, firstNames, ssn, examTitles }) => (
          <tr key={ssn}>
            <td>
              {lastName}, {firstNames}
            </td>
            <td>{ssn}</td>
            <td>
              {examTitles.map((title, index) => (
                <div key={index}>{title}</div>
              ))}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    <p
      className="js-unmapped-students-instructions"
      dangerouslySetInnerHTML={{ __html: translate('sa.unmapped_students.instructions') }}
    />
  </div>
)
